import "./navigation.scss";

import React, {useEffect, useRef, useState} from "react";
import {GitHubLogoIcon, LinkedInLogoIcon} from '@radix-ui/react-icons';
import PatriciusdevIcon from "../../icons/patriciusdevIcon";
import AvaloniaIcon from "../../icons/avaloniaIcon";

let browserRouterDom;

/**
 * Custom hook for determining the scroll direction of the window.
 * It updates the scroll direction state based on the user's scroll actions.
 * @returns {string} The current scroll direction ('up' or 'down').
 */
function useScrollDirection() {
    const [scrollDir, setScrollDir] = useState("up");

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDir && (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)) setScrollDir(direction);

            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', updateScrollDirection);

        return () => window.removeEventListener('scroll', updateScrollDirection);
    }, [scrollDir]);

    return scrollDir;
}

/**
 * Navigation component that manages navigation visibility and interaction.
 * It uses state to control navigation openness and responds to scroll and mouseover events.
 *
 * Inspired by OpenAI Navigation-bar state DD.MM.YYYY: 05.04.2024 https://openai.com/api.
 */
function Component() {
    const [navOpen, setNavOpen]       = useState(false);
    const [navAnchors, setNavAnchors] = useState({
        landing: false,
        live: false
    });
    const navContainerRef = useRef(null);
    const scrollDir       = useScrollDirection();

    useEffect(() => {
        browserRouterDom      = document.querySelector(".browser-router");
        const handleMouseOver = (event) => {
            if (event.target === navContainerRef.current) {
                toggleNav();
                navContainerRef.current.removeEventListener('mouseover', handleMouseOver);
            }
        };

        if (navOpen && navContainerRef.current) navContainerRef.current.addEventListener('mouseover', handleMouseOver);
        else if (navContainerRef.current)       navContainerRef.current.removeEventListener('mouseover', handleMouseOver);


        return () => {
            if (navContainerRef.current) navContainerRef.current.removeEventListener('mouseover', handleMouseOver);
        };
    }, [navOpen]);


    /**
     * Toggles the visibility and state of navigation anchors based on user interaction.
     * It also applies a CSS transform to scale down the browser router when an anchor is active.
     * This function checks if an anchor is provided and toggles its active state while resetting others.
     * @param anchor {string | null} The anchor identifier to toggle, affecting its state. Defaults to null.
     */
    function toggleNav(anchor = null) {
        const val = anchor ? !navAnchors[anchor] : false;

        // Resets all anchors except the one being toggled
        setNavAnchors(prevAnchors => {
            return Object.keys(prevAnchors).reduce((acc, key) => {
                acc[key] = key === anchor ? !prevAnchors[key] : false;
                return acc;
            }, {});
        });

        browserRouterDom.style.transform = `scale(${val ? .93 : 1})`;
        setNavOpen(val);
    }

    /**
     * Handles anchor navigation within the page by scrolling to the corresponding DOM element.
     * Prevents default link behavior and toggles navigation visibility.
     * @param event {React.MouseEvent<HTMLAnchorElement>} The event triggered by clicking a navigation link.
     */
    const scrollToAnchor = (event) => {
        event.preventDefault();
        const href = event.currentTarget.getAttribute('href');
        const offsetTop = document.querySelector(href).offsetTop;

        window.lenis.scrollTo(offsetTop);

        toggleNav();
    };

    /**
     * Retrieves the CSS class for a navigation anchor based on its current state.
     * Returns 'open' if the anchor is active, 'disabled' if another anchor is active, or an empty string otherwise.
     * @param anchor {string} The anchor identifier to evaluate.
     * @returns {string} The CSS class for the anchor.
     */
    const getAnchorClass = (anchor) => {
        if (navAnchors[anchor]) return "open";
        if (Object.values(navAnchors).some(value => value === true)) return "disabled";
        return "";
    }

    return (
        <>

            <div
                ref={navContainerRef}
                className={`navigation-container ${navOpen ? "open" : ""}`}
            >

                <div className={`navigation ${scrollDir === "down" ? "closed" : ""}`}>
                    <div className="navigation-bar md:m-2 m-1">
                        <div className="logo md:mr-16 mr-6">
                            <a
                                href="#introduction"
                                className="text--xl"
                                onClick={scrollToAnchor}
                            >
                                <PatriciusdevIcon width="calc(1rem + .2em)"/>
                            </a>
                        </div>

                        <div className="anchors">
                            <div className="anchor-item">
                                <a className={`text--xs ${getAnchorClass("landing")}`}
                                   onClick={() => toggleNav("landing")}>Landing
                                </a>

                                <div className="anchor-container">
                                    <div className={`animation-container ${getAnchorClass("landing")}`}>
                                        <div className="anchor-open md:pt-10 pt-4">
                                            <div className="md:mb-4 mb-4">
                                                <a href="#about" className="text--xs" onClick={scrollToAnchor}>About</a>
                                            </div>
                                            <div className="md:mb-4 mb-4">
                                                <a href="#my-works" className="text--xs" onClick={scrollToAnchor}>My
                                                    Works</a>
                                            </div>
                                            <div className="md:mb-4 mb-4">
                                                <a href="#story" className="text--xs" onClick={scrollToAnchor}>Story</a>
                                            </div>
                                            <div className="md:mb-4 mb-4">
                                                <a href="#contact" className="text--xs"
                                                   onClick={scrollToAnchor}>Contact</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="anchor-item md:ml-10 ml-6">
                                <a className={`text--xs ${getAnchorClass("live")}`}
                                   onClick={() => toggleNav("live")}>Live-Projects
                                </a>

                                <div className="anchor-container">
                                    <div className={`animation-container ${getAnchorClass("live")}`}>
                                        <div className="anchor-open md:pt-10 pt-4">
                                            <div className="md:mb-4 mb-4">
                                                <a href="https://avalonia.space" target="_blank"
                                                   className="text--xs">Avalonia</a>
                                            </div>
                                            <div className="md:mb-4 mb-4">
                                                <a href="https://eso-stats.pro" target="_blank"
                                                   className="text--xs">ESO-Stats</a>
                                            </div>
                                            <div className="md:mb-4 mb-4">
                                                <a href="https://immobilien-makler-akademie.com" target="_blank"
                                                   className="text--xs">IMA</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="navigation-externals md:w-150 l:w-200 w-50">
                <div className="bottom stick-to-bottom md:m-16 mx-2 my-6">
                    <div className="magnetic">
                        <a
                            href="https://avalonia.space"
                            target="_blank"
                            className="text--xl"
                        >
                            <AvaloniaIcon width="calc(1rem + .2em)"/>
                        </a>
                    </div>
                    <div className="magnetic">
                        <a
                            href="https://github.com/DajosPatryk"
                            target="_blank"
                            className="text--xl"
                        >
                            <GitHubLogoIcon width="calc(1rem + .2em)" height="calc(1rem + .3em)"/>
                        </a>
                    </div>
                    <div className="magnetic">
                        <a
                            href="https://www.linkedin.com/in/patryk-dajos/"
                            target="_blank"
                            className="text--xl"
                        >
                            <LinkedInLogoIcon width="calc(1rem + .2em)" height="calc(1rem + .3em)"/>
                        </a>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Component;
