import {gsap} from "gsap";

const STRENGTH = 70;
let magnets;

/**
 * Initializes magnetic effect on all elements with the "magnetic" class.
 * Each magnetic element will react to mouse movements and resets on mouse out.
 */
export function initMagnetic() {
    magnets = document.querySelectorAll(".magnetic");
    magnets.forEach((magnet) => {
        magnet.addEventListener("mousemove", moveMagnet);
        magnet.addEventListener("mouseout", resetMagnet);
    });
}

/**
 * Removes magnetic effect from all elements previously initialized.
 * Cleans up event listeners to prevent memory leaks.
 */
export function destroyMagnetic() {
    magnets.forEach((magnet) => {
        magnet.removeEventListener("mousemove", moveMagnet);
        magnet.removeEventListener("mouseout", resetMagnet);
    });
}

/**
 * Applies a magnetic effect to the target element based on mouse position.
 * Adjusts the element's position dynamically to create a floating effect.
 * @param event {MouseEvent} The mouse event triggering this behavior.
 */
function moveMagnet(event) {
    let magnetButton = event.currentTarget;
    let bounding = magnetButton.getBoundingClientRect();

    let xTo = gsap.quickTo(magnetButton, "x", {
            duration: 1,
            ease: "Power4.easeOut",
        }),
        yTo = gsap.quickTo(magnetButton, "y", {
            duration: 1,
            ease: "Power4.easeOut",
        });

    xTo(
        ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) *
        STRENGTH
    );
    yTo(
        ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) *
        STRENGTH
    );
}

/**
 * Resets the element's position to its original state.
 * This function is called when the mouse exits the element.
 * @param event {MouseEvent} The mouse event triggering this behavior.
 */
function resetMagnet(event){
    gsap.to(event.currentTarget, {
        x: 0,
        y: 0,
        ease: "Power4.easeOut",
        duration: 1,
    });
}