import React from "react";

function Icon({ width }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.000000 266.000000"
            width={width}
        >
            <g transform="translate(0.000000,266.000000) scale(0.100000,-0.100000)"
               fill="var(--foreground)">
                <path d="M1113 2344 c-22 -38 -149 -262 -283 -499 -134 -236 -351 -619 -483
-850 -132 -231 -250 -438 -263 -460 -12 -22 -30 -53 -39 -69 -21 -36 -9 -49
35 -36 23 7 36 6 41 -1 4 -7 23 -8 59 -2 l52 8 279 490 c153 270 360 635 461
812 101 178 187 321 192 320 12 -4 876 -1439 876 -1455 0 -4 -303 -6 -674 -4
l-674 2 -45 -82 c-25 -46 -43 -86 -41 -90 6 -9 1754 -11 1754 -3 0 5 -34 60
-89 145 -9 14 -254 422 -545 906 -290 485 -538 894 -550 909 l-22 28 -41 -69z"/>
                <path d="M2144 1752 c-127 -169 -237 -316 -244 -327 -11 -18 -6 -31 35 -98 25
-42 50 -77 54 -77 5 0 19 15 32 34 13 18 42 58 65 87 23 30 121 161 218 293
l177 238 -52 79 c-28 43 -52 79 -53 79 0 0 -105 -138 -232 -308z"/>
                <path d="M1088 1753 l-47 -85 108 -176 c59 -97 197 -322 307 -499 l199 -323
93 0 c105 0 103 -3 57 78 -67 119 -644 1060 -664 1084 -3 3 -27 -32 -53 -79z"/>
                <path d="M2539 1777 c-8 -7 -122 -153 -252 -325 l-237 -314 17 -31 c9 -18 32
-55 51 -84 l35 -53 28 38 c16 20 129 172 252 337 l224 300 -45 65 c-59 84 -55
80 -73 67z"/>
            </g>
        </svg>
    );
}

export default Icon;
