import {gsap} from "gsap";

/**
 * Creates vertical scroll-driven animation for a collection of elements, creating frosted-glass effect.
 * Utilizes GSAP's fromTo method with a ScrollTrigger to manage the animation timing based on the scroll position.
 * @param elements {Element[]} Array of DOM elements to animate.
 * @returns {GSAPAnimation[]} Array of animation instances for each element.
 */
export default function frostedGlassAnimation (elements) {
    const aniRefs = [];

    elements.forEach(elem => {
        aniRefs.push(gsap.fromTo(elem,
            { backdropFilter: "blur(64px)" },
            {
                backdropFilter: "blur(0px)",
                duration: 1,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: elem,
                    start: "top bottom",
                    end: "center center",
                    scrub: true
                }
            }
        ));
    });

    return aniRefs;
}