import "./assets/app.scss";

import React, {useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./views/home/homeView";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Lenis from 'lenis'
import Navigation from "./components/ui/navigation/navigation";
import {initMagnetic, destroyMagnetic} from "./components/effects/magnetic/magnetic";
import "./components/effects/magnetic/magnetic.scss";
import {isMobile} from "react-device-detect";
import LoadingScreen from "./components/ui/loadingScreen/loadingScreen";

/**
 * GSAP animations.
 */
gsap.registerPlugin(ScrollTrigger);

/**
 * Lenis smooth scroll.
 */
window.lenis = new Lenis({
    lerp: 0.057,
    easing: (x) => Math.sqrt(1 - Math.pow(x - 1, 2))
});

function raf(time) {
    window.lenis.raf(time)
    requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
window.lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => window.lenis.raf(time * 1000));
gsap.ticker.lagSmoothing(0)

function App() {
    useEffect(() => {
        if (!isMobile) initMagnetic();

        return () => {
            if (!isMobile) destroyMagnetic();
        }
    }, []);

    return (
        <div className="app">
            <Navigation/>

            <LoadingScreen/>

            <div className="browser-router">
                <BrowserRouter>
                    <Routes>
                        <Route index element={<Home/>}/>
                    </Routes>
                </BrowserRouter>
            </div>

            <div className="fixed-background"/>
        </div>
    );
}

export default App;
