import {gsap} from "gsap";

/**
 * Creates vertical scroll-driven animations for a collection of elements, creating parallax effect.
 * Utilizes GSAP's fromTo method with a ScrollTrigger to manage the animation timing based on the scroll position.
 * @param elements {Element[]} Array of DOM elements to animate.
 * @returns {GSAPAnimation[]} Array of animation instances for each element.
 */
export default function parallaxAnimation (elements) {
    const aniRefs = [];

    elements.forEach(elem => {
        aniRefs.push(gsap.fromTo(elem,
            { top: "10%" },
            {
                top: "-10%",
                duration: 1,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: elem,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true
                }
            }
        ));
    });

    return aniRefs;
}