import React, {useEffect, useState} from "react";

const expStartDate    = new Date("2014-08-07T09:00:00+0000"),
      proexpStartDate = new Date("2020-06-01T09:00:00+0000");

function String() {
    const [exp, setExp] = useState('');
    const [proexp, setProexp] = useState('');

    useEffect(updateExp);

    function updateExp() {
        const now = new Date();
        const expDiff = dateDifference(expStartDate, now);
        const proexpDiff = dateDifference(proexpStartDate, now);

        setExp(expDiff);
        setProexp(proexpDiff);
    }

    function dateDifference(startDate, endDate) {
        const diff = endDate - startDate;
        const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
        const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24));
        return `${years} years ${days} days`;
    }

    return (
        <div className="text--center">
            <h3>{exp} of experience</h3>
            <span className="text--s text-util-500">{proexp} of professional experience</span>
        </div>
    );
}

export default String;