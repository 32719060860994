import axios from "axios";

/**
 * Checks if a given hostname is a local IP address.
 *
 * This function determines if the provided hostname corresponds to a local IP address.
 * It identifies loopback addresses, private network IPs, and localhost.
 *
 * @param {string} hostname - The hostname or IP address to check.
 * @returns {boolean} - Returns `true` if the hostname is a local IP address, otherwise `false`.
 */
const isLocalIpAddress = (hostname: string): boolean => {
    return hostname === 'localhost' ||
        hostname.startsWith('127.') || // Loopback
        /^192\.168\./.test(hostname) || // Private network IP
        /^10\./.test(hostname) ||
        /^172\.(1[6-9]|2[0-9]|3[0-1])\./.test(hostname);
}

// Determines API url
const baseURL = isLocalIpAddress(window.location.hostname)
    ? process.env.REACT_APP_API_ADDRESS_DEV
    : process.env.REACT_APP_API_ADDRESS_PROD;

/**
 * Makes a GET request to the specified URL.
 *
 * @param {string} url - The endpoint to send the GET request to.
 * @param {Function} _callback - The callback function to handle the response.
 * @returns {Promise<void>}
 */
export async function get(url: string, _callback: (res: { success: boolean; res: any[] }) => void): Promise<void> {
    await axios({
        url: `${baseURL}${url}`,
        method: "get",
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err));
        });
}

/**
 * Makes a POST request to the specified URL with the provided body.
 *
 * @param {string} url - The endpoint to send the POST request to.
 * @param {object} body - The data to send in the POST request.
 * @param {Function} _callback - The callback function to handle the response.
 * @returns {Promise<void>}
 */
export async function post(url: any, body: any, _callback: (arg0: { success: boolean; res: any; }) => void): Promise<void> {
    await axios({
        url: `${baseURL}${url}`,
        method: "post",
        data: body,
    })
        .then((res) => {
            _callback({
                success: true,
                res: res.data
            });
        })
        .catch((err) => {
            if (isLocalIpAddress(window.location.hostname)) console.log(err);
            _callback(errorHandler(err));
        });
}

/**
 * Handles errors from API requests.
 * @param {Object} err - The error object from an Axios request.
 */
function errorHandler(err: any): {success: boolean, res: any[]} {
    try {
        if (err.response) {
            if (err.response.data instanceof Array || err.response.data instanceof Object) return {
                success: false,
                res: [].concat(err.response.data)
            }
        }
    } catch(err) {
    }

    return {
        success: false,
        res: [
            {
                code: 500,
                message: "Internal server error."
            }
        ]
    }
}