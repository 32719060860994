import "./supercarScene.scss";

import React, {useEffect} from "react";
import * as Three from 'three';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {DRACOLoader} from "three/addons/loaders/DRACOLoader.js";

/**
 * Initializing scene elements to global loading object.
 */
window.isLoading = {
    ...window.isLoading,
    supercarWireframeScene: window.innerWidth > 580,
};
let sceneContainer,
    wireframeSceneDom,
    wireframeScene = new Three.Scene(),
    wireframeModel,
    loader            = new GLTFLoader(),
    dracoLoader       = new DRACOLoader(),
    wireframeRenderer = new Three.WebGLRenderer({antialias: false, alpha: true, powerPreference: "low-power"}),
    camera,
    aniRef,
    posY = 2.385,
    posZ = 5.428,
    posX = 9.945,
    rotY = 0.930,
    rotZ = 0.339,
    rotX = -0.414;

/**
 * Initializes and loads the 3D scene including camera, renderer, and model.
 * Sets up the scene environment and starts the rendering process once the GLTF model is loaded.
 * @param _callback {Function} Callback function to execute once the scene is fully loaded.
 */
function loadWireframe(_callback) {
    // Set scene
    dracoLoader.setDecoderPath('assets/3d/draco/');
    dracoLoader.setDecoderConfig({ type: 'js' });
    loader.setDRACOLoader(dracoLoader);
    wireframeRenderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
    wireframeSceneDom = wireframeRenderer.domElement;
    sceneContainer.appendChild(wireframeSceneDom);

    // Load scene
    loader.load("./assets/3d/audiR8/scene.gltf", function (gltf) {
            // Double-loading lock
            if (!window.isLoading.supercarWireframeScene) return;

            // Load model
            wireframeModel = gltf;
            wireframeScene.add(wireframeModel.scene);
            camera.position.y = posY;
            camera.position.z = posZ;
            camera.position.x = posX;
            camera.rotation.y = rotY;
            camera.rotation.z = rotZ;
            camera.rotation.x = rotX;
            wireframeScene.add(camera);

            wireframeScene.traverse((child) => {
                if (child.isMesh) {
                    child.material = new Three.MeshBasicMaterial({
                        color: 0xffffff,
                        wireframe: true,
                        transparent: true,
                        opacity: 0.02
                    });
                }
            });

            // Render scene
            wireframeRenderer.render(wireframeScene, camera);

            window.isLoading.supercarWireframeScene = false;
            _callback();
        }
    );
}

/**
 * Updates and renders the scene based on animation progress.
 * Adjusts camera, light and model dynamically.
 */
function renderScene(aniProgress) {
    const aniProgressHalf = Math.min(aniProgress * 2, 1);
    const rotationY       = (Math.PI / 400) * aniProgress;

    camera.position.y               = posY + .2 * aniProgressHalf;
    camera.position.x               = posX - 1.645 * aniProgressHalf;
    wireframeModel.scene.rotation.y = rotationY + aniProgress;

    wireframeRenderer.render(wireframeScene, camera);
}

/**
 * Adjusts the camera's aspect ratio and renderer's size to handle window resizing.
 */
function resizeScene() {
    camera.aspect = sceneContainer.offsetWidth / sceneContainer.offsetHeight;
    camera.updateProjectionMatrix();
    wireframeRenderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
}

function Scene({ progress }) {
    useEffect(() => {
        sceneContainer = document.querySelector(".supercar-wireframescene-container");
        camera         = new Three.PerspectiveCamera(30, sceneContainer.offsetWidth / sceneContainer.offsetHeight, 0.1, 1000);

        loadWireframe(function () {
            window.addEventListener('resize', resizeScene, false);
        });

        return () => {
            if (aniRef instanceof ScrollTrigger) aniRef.kill();
            if (sceneContainer && sceneContainer.firstChild) sceneContainer.removeChild(sceneContainer.firstChild);
        };
    }, []);

    useEffect(() => {
        if (!window.isLoading.supercarWireframeScene) renderScene(progress);
    }, [progress]);

    return (
        <div className="relative full-width">
            <div className="supercar-wireframescene-container center-horizontal"/>
        </div>
    );
}

export default Scene;