import React from "react";
import {isMobile} from "react-device-detect";

/**
 * Renders a series of div elements styled as stripes based on provided stripe data.
 * Each stripe is a div with a specified margin and height.
 * @param stripes {Object[]} Array of objects representing stripe properties, each containing:
 *                           m (margin top and bottom) and h (height) in pixels.
 * @returns A JSX element representing a series of styled divs.
 */
const RenderFooter = ({ stripes }) => (
    stripes.map(stripe =>
        <div
            key={stripe.m}
            className="bg-util-50"
            style={{
                marginTop:      `${stripe.m}px`,
                marginBottom:   `${stripe.m}px`,
                height:         `${stripe.h}px`
            }}
        />
    )
);

function Component() {
    const stripes = () => {
        const res = [];
        for (let m = isMobile ? 8 : 16, h = 3; m > 0; m--, h += 1.2) res.push({m, h});
        return res;
    }

    return (
        <div id="legal">
            <RenderFooter stripes={stripes()}/>
            <div className="md:py-24 py-12 lg:px-64 md:px-32 px-2 bg-util-50 text-util-950 z-20">
                <div className="grid grid-cols-2">
                    <div className="md:px-6 px-2 border-l border-util-950">
                        <h5>Avalonia™</h5>
                        <span className="text--xs">
                            patryk.dajos@avalonia.space<br/>
                            Nordweg 2, 03055 Cottbus<br/>
                            Germany
                        </span>
                    </div>
                    <div className="md:px-6 px-2 border-l border-util-950 font-medium">
                        <a
                            href=""
                            target="_blank"
                        >
                            Blogs
                        </a><br/>
                        <a
                            href="https://github.com/DajosPatryk"
                            target="_blank"
                        >
                            GitHub
                        </a><br/>
                        <a
                            href="https://www.linkedin.com/in/patryk-dajos/"
                            target="_blank"
                        >
                            LinkedIn
                        </a><br/>
                    </div>
                </div>
                <div className="spacer">
                    <p className="text--xs">
                        {process.env.REACT_APP_NAME} does not collect, store, or utilize any cookies or personal data.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Component;
