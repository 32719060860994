import "./snakeGame.scss";

import React, {useEffect, useState} from "react";
import Scoreboard from "./scoreboard";
import {isMobile} from 'react-device-detect';
import {Input} from "../../ui/input";
import {Button} from "../../ui/button";
import {CheckCircle} from "lucide-react";
import {ExclamationTriangleIcon} from "@radix-ui/react-icons";

let gameInterval = null,
    canvasSize   = {x: 20, y: 20},
    fps          = isMobile ? 6 : 9,
    score        = 0,
    direction    = "",
    playing      = false,
    snake        = [{x: 10, y: 10}],
    food         = {x: 2, y: 2, type: ""},
    sewage       = {x: -1, y: -1},
    chanceSuper  = 0.15,                    // Chance to spawn super food
    chanceSewage = 0.4,                     // Chance to spawn sewage
    name         = "",
    scoreboard   = new Scoreboard();

/**
 * Checks if the snake has eaten the food.
 * @returns {boolean} Returns true if the snake's head is at the same position as the food, otherwise false.
 */
const isFed = () => {
    if (snake[0].x === food.x && snake[0].y === food.y){
        if (food.type === "super") return "super";
        return "normal";
    }
}

/**
 * Checks if the snake has eaten sewage.
 * @returns {boolean} Returns true if the snake's head is at the same position as the sewage, otherwise false.
 */
const isToxified = () => {
    return snake[0].x === sewage.x && snake[0].y === sewage.y;
}

/**
 * Checks if the snake has collided with itself.
 * @returns {boolean} Returns true if the snake has collided with itself, otherwise false.
 */
const isCollided = () => {
    const head = snake[0];

    for (let i = 1; i < snake.length; i++) {
        if (head.x === snake[i].x && head.y === snake[i].y) return true;
    }

    return false;
}

/**
 * Plays an audio cue.
 * @param {string} src - Source to audio file.
 * @returns {void}
 */
function playAudio(src) {
    const audio = new Audio(src);
    audio.play();
}

/**
 * Resets the game state.
 * @returns {void}
 */
function reset() {
    score = 0;
    direction = "";
    snake = [{x: 10, y: 10}];
    respawnElements();
}

/**
 * Changes the direction of the snake.
 * @param {string} newDirection - The new direction ("up", "down", "left", "right").
 * @returns {void}
 */
function changeDirection(newDirection) {
    if (!playing) playing = true;

    switch (newDirection) {
        case "up":    if(direction === "down") return; break;
        case "down":  if(direction === "up") return; break;
        case "left":  if(direction === "right") return; break;
        case "right": if(direction === "left") return; break;
    }

    direction = newDirection;
}

/**
 * Handles keydown events to change the snake's direction.
 * @param {KeyboardEvent} event - The keyboard event.
 * @returns {void}
 */
function handleKeyDown(event) {
    if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)) {
        event.preventDefault();

        switch (event.key) {
            case "ArrowUp":    changeDirection("up"); break;
            case "ArrowDown":  changeDirection("down"); break;
            case "ArrowLeft":  changeDirection("left"); break;
            case "ArrowRight": changeDirection("right"); break;
        }
    }
}

/**
 * Respawns food, super-food and sewage at random positions on the canvas.
 * @returns {void}
 */
function respawnElements() {
    let isOccupied;

    do {
        food.x = Math.floor(Math.random() * canvasSize.x);
        food.y = Math.floor(Math.random() * canvasSize.y);
        isOccupied = snake.some(segment => segment.x === food.x && segment.y === food.y);

        if (Math.random() < chanceSuper) food.type = "super";
        else food.type = "";
    } while (isOccupied);

    if (Math.random() < chanceSewage) spawnSewage();
    else { sewage.x = -1; sewage.y = -1; }
}

/**
 * Spawns the sewage at a random position within 3 blocks of the food on the canvas.
 * @returns {void}
 */
function spawnSewage() {
    let isOccupied;

    do {
        sewage.x = food.x + Math.floor(Math.random() * 7) - 3;
        sewage.y = food.y + Math.floor(Math.random() * 7) - 3;
        sewage.x = Math.max(0, Math.min(sewage.x, canvasSize.x - 1));
        sewage.y = Math.max(0, Math.min(sewage.y, canvasSize.y - 1));

        isOccupied = snake.some(segment => segment.x === sewage.x && segment.y === sewage.y) || (sewage.x === food.x && sewage.y === food.y);
    } while (isOccupied);
}

/**
 * Feeds the snake by adding a new segment to its tail.
 * @returns {void}
 */
function feedSnake() {
    playAudio("./assets/audio/software_developer_snake_eat.mp3");
    let newSegment = {x: snake[snake.length - 1].x, y: snake[snake.length - 1].y};

    if (snake.length === 1) {
        switch (direction) {
            case "up":    newSegment.y -= 1; break;
            case "down":  newSegment.y += 1; break;
            case "left":  newSegment.x -= 1; break;
            case "right": newSegment.x += 1; break;
        }
    } else {
        let lastSegment = snake[snake.length - 1];
        let secondLastSegment = snake[snake.length - 2];

        if (lastSegment.x === secondLastSegment.x) newSegment.y += (lastSegment.y > secondLastSegment.y) ? 1 : -1;
        else if (lastSegment.y === secondLastSegment.y) newSegment.x += (lastSegment.x > secondLastSegment.x) ? 1 : -1;
    }

    score++;
    snake.push(newSegment);
}

/**
 * Super feeds the snake by adding 3 new segments to its tail.
 * @returns {void}
 */
function superFeedSnake() {
    for (let i = 0; i < 3; i++) { setTimeout(feedSnake, i * 120); }
}

/**
 * Toxifies the snake by removing 1 segment from its tail.
 * @returns {void}
 */
function toxifySnake() {
    playAudio("./assets/audio/software_developer_snake_toxified.mp3");

    for (let i = 0; i < 2; i++) {
        if(snake.length > 1) {
            snake.pop();
            score--;
        }
    }
    sewage.x = -1; sewage.y = -1;
}

/**
 * Moves the snake in the current direction.
 * @returns {void}
 */
function moveSnake() {
    let prevPos = {x: snake[0].x, y: snake[0].y};

    switch (direction) {
        case "up":    snake[0].y -= 1; break;
        case "down":  snake[0].y += 1; break;
        case "left":  snake[0].x -= 1; break;
        case "right": snake[0].x += 1; break;
    }

    // Handles overflow/underflow
    if (snake[0].x >= canvasSize.x || snake[0].x < 0) snake[0].x = (snake[0].x >= canvasSize.x) ? 0 : canvasSize.x - 1;
    if (snake[0].y >= canvasSize.y || snake[0].y < 0) snake[0].y = (snake[0].y >= canvasSize.y) ? 0 : canvasSize.y - 1;

    for (let i = 1; i < snake.length; i++) {
        let currentPos = {x: snake[i].x, y: snake[i].y};
        snake[i].x = prevPos.x;
        snake[i].y = prevPos.y;
        prevPos = currentPos;
    }
}

/**
 * Resets the game when it's over.
 * @returns {void}
 */
function gameOver() {
    playAudio("./assets/audio/software_developer_snake_death.mp3");
    if(scoreboard.getPersonalScore().score < score) scoreboard.setPersonalScore(name, score);
    reset();
}

/**
 * Generates the pixel array representing the game state for rendering.
 * @returns {Array<Array<{ type: string }>>} Returns a 2D array representing the game state where each element is an object with a 'type' property.
 */
const pixels = () => {
    const array = new Array(20);

    for (let row = 0; row < canvasSize.y; row++) {
        array[row] = new Array(20);
        for (let col = 0; col < canvasSize.x; col++) {
            array[row][col] = {type: ""};

            // Checks if coordinates match food
            if (food.x === col && food.y === row) array[row][col].type = `food${(food.type === "super") ? "-super" : ""}`;

            // Checks if coordinates match sewage
            else if (sewage.x === col && sewage.y === row) array[row][col].type = "sewage";

            else {
                // Checks if the coordinates match snake
                snake.forEach((segment, index) => {
                    if (segment.x === col && segment.y === row) {
                        if (index === 0) array[row][col].type = "snake-head";
                        else array[row][col].type = "snake-tail";
                    }
                });
            }
        }
    }

    return array;
}

function Game() {
    const [, forceUpdate] = useState();
    const reRender = () => forceUpdate(undef => !undef);

    function updateFrame() {
        moveSnake();
        if (isFed()) {
            if(food.type === "super") superFeedSnake();
            else feedSnake();
            respawnElements();
        }
        else if (isToxified()) toxifySnake();
        else if (isCollided()) gameOver();
        reRender();
    }

    useEffect(() => {
        gameInterval = setInterval(updateFrame, 1000 / fps);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            clearInterval(gameInterval);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <div className={`snake-game ${playing ? "playing" : ""}`}>
            <div className="snake-game-container">
                {isMobile && (
                    <>
                        <div className="turn-up" onClick={() => changeDirection("up")}/>
                        <div className="turn-down" onClick={() => changeDirection("down")}/>
                        <div className="turn-right" onClick={() => changeDirection("right")}/>
                        <div className="turn-left" onClick={() => changeDirection("left")}/>
                    </>
                )}
                <div className="snake-game-canvas">
                    {pixels().flatMap((row, i) =>
                        row.map((cell, j) => (
                            <div key={`cell-${i}-${j}`} className={`cell ${cell.type}`}/>
                        ))
                    )}
                </div>
                <p className="snake-game-score text-util-700">Score {score}</p>
                <p
                    className="snake-game-instruction text-util-700 text--center"
                    style={{
                        opacity: (direction === "") ? 1 : 0
                    }}
                >
                    {`${isMobile ? "Touch anywhere" : "Press any 'Arrow Key'"} to start`}
                </p>
            </div>

            {/*<div className="scoreboard md:mt-8 mt-32">
                <p className="text--l">Leaderboard</p>
                {scoreboard.getPersonalScore().score &&
                    <div className="relative">
                        <p className="text--s text-util-500">Personal Highscore {scoreboard.getPersonalScore().score}</p>
                        <div className="relative inline-flex md:mt-3 mt-2">
                            <Input
                                type="text"
                                placeholder="Nickname"
                                value={name}
                                onChange={(e) => name = e.target.value}
                            />
                            <Button
                                className="text-util-50 md:mx-3 mx-2"
                                onClick={() => scoreboard.publishScore(name)}
                                disabled={scoreboard.getPublishLock()}
                            >
                                Publish
                            </Button>
                        </div>
                        <div className="mt-1">
                            {scoreboard.getPublishSuccess() &&
                                <p className="text--xs text-success"><CheckCircle className="inline" width="1em" height="1em"/> Published!</p>
                            }
                            {scoreboard.getErrors().map(err => {
                                return <p className="text--xs text-danger"><ExclamationTriangleIcon className="inline" width="1em" height="1em"/> {err.message}</p>
                            })}
                        </div>
                    </div>
                }

                {scoreboard.getTopFive().length > 0 &&
                    <div className="md:mt-6 mt-4">
                        {scoreboard.getTopFive().map((player, index) => {
                            if (index === 0)
                                return <p className="rainbow-text" key={index}>#{index + 1} {player.name} - {player.score}</p>;
                            else if (index === 1)
                                return <p className="text--s" key={index}>#{index + 1} {player.name} - {player.score}</p>;
                            else
                                return <p className="text--xs text-util-500" key={index}>#{index + 1} {player.name} - {player.score}</p>;
                        })}
                    </div>
                }
                {scoreboard.getTopFive().length === 0 &&
                    <p className="md:mt-6 mt-4 text--s text-util-500">No leaderboard could be loaded.</p>
                }
            </div>*/}
        </div>
    );
}

export default Game;
