import {get, post} from "../../../lib/fetchUtils";

export default class Scoreboard {
    private personal: { name: string | null, score: number | null };
    private topFive: any[];
    private publishSuccess: boolean;
    private publishLock: boolean;
    private errors: any[];

    constructor() {
        this.personal       = { name: null, score: null };
        this.topFive        = [];
        this.publishSuccess = false;
        this.publishLock    = true;
        this.errors         = [];
        /* TODO: API is inactive
        this.loadLocalStorage();
        this.loadTopFive();
        */
    }

    /**
     * Saves the current state of the personal and topFive properties to local storage.
     */
    private saveLocalStorage(): void {
        localStorage.setItem("snakeGame", JSON.stringify({
            personal: this.personal,
            topFive: this.topFive,
            publishLock: this.publishLock
        }));
    }

    /**
     * Loads the saved state of the personal and topFive properties from local storage.
     */
    private loadLocalStorage(): void {
        const raw = localStorage.getItem("snakeGame");
        const { personal = { name: null, score: null }, topFive = [], publishLock = false } = raw ? JSON.parse(raw) : {};

        this.personal    = personal;
        this.topFive     = topFive;
        this.publishLock = publishLock;
    }

    /**
     * Loads the saved state of the topFive properties from API.
     */
    private async loadTopFive(): Promise<void> {
        await get("/api/score", ({ success, res }): void => {
            if (success) this.topFive = res;
        });
    }

    /**
     * Publishes the user score to API.
     */
    public async publishScore(name: string): Promise<void> {
        this.personal.name  = name;
        this.saveLocalStorage();

        this.publishSuccess = false;
        if (this.publishLock) return;

        await post("/api/score", this.personal, ({ success, res }): void => {
            if (success) {
                this.publishSuccess = true;
                this.errors         = [];
                this.publishLock    = true;
                this.saveLocalStorage();
                this.loadTopFive();
            } else {
                this.publishSuccess = false;
                this.errors         = res;
            }
        });
    }

    /**
     * Retrieves the personal object containing name and score.
     * @returns {Object} The personal object with name and score.
     */
    public getPersonalScore(): { name: string | null, score: number | null } {
        return this.personal;
    }

    /**
     * Sets the personal object with the provided name and score, and saves it to local storage.
     * @param name {string | null} The name to be set.
     * @param score {number | null} The score to be set.
     */
    public setPersonalScore(name: string | null, score: number | null): void {
        this.personal = { name, score };
        this.publishLock = false;
        this.saveLocalStorage();
    }

    /**
     * Retrieves the array of top five high scores.
     * @returns {Array} The topFive array.
     */
    public getTopFive(): any[] {
        return this.topFive;
    }

    /**
     * Retrieves the boolean if last publishing attempt was successful.
     * @returns {Boolean} True or false.
     */
    public getPublishSuccess(): boolean {
        return this.publishSuccess;
    }

    /**
     * Retrieves the boolean if publishing is locked.
     * @returns {Boolean} True or false.
     */
    public getPublishLock(): boolean {
        return this.publishLock;
    }

    /**
     * Retrieves the array of errors related to pushScore.
     * @returns {Array} The error array.
     */
    public getErrors(): any[] {
        return this.errors;
    }
}