import "./loadingScreen.scss";

import React, {useEffect, useState} from "react";

let browserRouterDom,
    lock     = false,   // Loading screen can only happen once.
    fps      = 12,
    aniIndex = 0;

/**
 * Renders animation on loading-screen finish.
 */
function renderLoadingFinishedAnimation(setFunc) {
    setFunc(true);
    browserRouterDom.style.transform = "scale(1)";
    window.scroll(0, 0);
}

/**
 * Loading screen component for intial load of the homepage.
 */
function Component() {
    const [state, setState]           = useState(false);
    const [cmdLoading, setCmdLoading] = useState("/");
    let isLoadedInterval              = null;
    let loadingAniInterval            = null;

    function renderCmdLoadingAnimation() {
        aniIndex++;
        switch (aniIndex % 4) {
            case 0: setCmdLoading("/"); break;
            case 1: setCmdLoading("-"); break;
            case 2: setCmdLoading("\\"); break;
            case 3: setCmdLoading("|"); break;
        }
    }

    useEffect(() => {
        if(lock) return;
        lock               = true;
        browserRouterDom   = document.querySelector(".browser-router");
        loadingAniInterval = setInterval(renderCmdLoadingAnimation, 1000 / fps);

        setTimeout(() => {

            isLoadedInterval = setInterval(() => {
                const allLoaded = Object.values(window.isLoading).every(status => !status);
                if (allLoaded) {
                    setTimeout(() => {
                        renderLoadingFinishedAnimation(setState);
                        clearInterval(loadingAniInterval);
                        }, 300);
                    clearInterval(isLoadedInterval);
                }
            }, 100);

        }, 500);
    }, []);

    return (
        <div className={`loading-container ${state ? "finished" : "loading"}`}>
            <div className="loading-screen">
                <div className="center text--l text--center font-roboto-mono font-light">
                    <p>{cmdLoading}</p>
                </div>
            </div>
        </div>
    );
}

export default Component;
