import "./lineArt.scss";
import {useEffect, useRef, useState} from "react";

const originalWidth = 1920;
const originalStrokeWidth = 1;

function Component() {
    const [strokeWidth, setStrokeWidth] = useState(1);
    const holderRef = useRef();

    function calculateStrokeWidth() {
        if (holderRef.current) {
            const currentWidth = holderRef.current.offsetWidth;
            const scaleFactor = originalWidth / currentWidth;
            setStrokeWidth(originalStrokeWidth * Math.ceil(scaleFactor * 10 * 1.5) / 10);
        }
    }

    useEffect(() => {
        calculateStrokeWidth();
        window.addEventListener('resize', calculateStrokeWidth, false);
    }, []);

    return (
        <div className="line-art-container">
            <div ref={holderRef} className="line-art-holder heart">
                <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                    <path
                        strokeWidth={strokeWidth}
                        stroke="var(--foreground)"
                        fill="none" strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M1.5-.5c0,75,.5,151.5,0,226,2.19-13.36,3.5-31.5,11.5-46.5,6-12,19-21,33-18,14,4,16,20,13.32,32.49-1.85,11.58-5.58,22.92-10.78,33.42,4.28-8.51,10.55-16,18.14-21.74s18.08-11.39,27.66-7.62c18.55,7.32,12,31.65,3.75,44.12-8.78,13.27-22,22.78-34.94,31.64s-25.64,17.98-37.03,29.16c-8.69,8.53-18.08,17.66-24.62,28.03,0,0,.5,499.5,0,749.5"/>
                </svg>
            </div>
        </div>
    );
}

export default Component;
