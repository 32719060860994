import "./phoneScene.scss";

import React, {useEffect} from "react";
import * as Three from 'three';
import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
import {DRACOLoader} from "three/addons/loaders/DRACOLoader.js";

/**
 * Initializing scene elements to global loading object.
 */
window.isLoading = {
    ...window.isLoading,
    phoneWireframeScene: true,
};
let sceneContainer,
    wireframeSceneDom,
    wireframeScene = new Three.Scene(),
    wireframeModel,
    loader            = new GLTFLoader(),
    dracoLoader       = new DRACOLoader(),
    wireframeRenderer = new Three.WebGLRenderer({antialias: false, alpha: true, powerPreference: "low-power"}),
    camera,
    posY        = .07,
    posZ        = .166,
    posX        = .24,
    rotY        = 0.930,
    rotZ        = 0.339,
    rotX        = -0.414;

/**
 * Initializes and loads the 3D scene including camera, renderer, and model.
 * Sets up the scene environment and starts the rendering process once the GLTF model is loaded.
 * @param _callback {Function} Callback function to execute once the scene is fully loaded.
 */
function loadWireframe(_callback) {
    // Set scene
    dracoLoader.setDecoderPath('assets/3d/draco/');
    dracoLoader.setDecoderConfig({ type: 'js' });
    loader.setDRACOLoader(dracoLoader);
    wireframeRenderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
    wireframeSceneDom = wireframeRenderer.domElement;
    sceneContainer.appendChild(wireframeSceneDom);

    // Load scene
    loader.load("./assets/3d/iphone14pro/scene.gltf", function (gltf) {
            // Double-loading lock
            if (!window.isLoading.phoneWireframeScene) return;

            // Load model
            wireframeModel = gltf;
            wireframeScene.add(wireframeModel.scene);
            camera.position.y = posY;
            camera.position.z = posZ;
            camera.position.x = posX;
            camera.rotation.y = rotY;
            camera.rotation.z = rotZ;
            camera.rotation.x = rotX;
            wireframeScene.add(camera);

            wireframeScene.traverse((child) => {
                if (child.isMesh) {
                    child.material = new Three.MeshBasicMaterial({
                        color: 0xffffff,
                        wireframe: true,
                        transparent: true,
                        opacity: 0.05
                    });
                }
            });

            // Render scene
            wireframeRenderer.render(wireframeScene, camera);

            window.isLoading.phoneWireframeScene = false;
            _callback();
        }
    );
}

/**
 * Updates and renders the scene based on animation progress.
 * Adjusts camera, light and model dynamically.
 */
function renderScene(aniProgress) {
    wireframeModel.scene.rotation.y = aniProgress * Math.PI * 1.2;
    if(aniProgress > 0) wireframeRenderer.render(wireframeScene, camera);
}

/**
 * Adjusts the camera's aspect ratio and renderer's size to handle window resizing.
 */
function resizeScene() {
    camera.aspect = sceneContainer.offsetWidth / sceneContainer.offsetHeight;
    camera.updateProjectionMatrix();
    wireframeRenderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
}

function Scene({ progress }) {
    useEffect(() => {
        sceneContainer = document.querySelector(".phone-wireframescene-container");
        camera         = new Three.PerspectiveCamera(30, sceneContainer.offsetWidth / sceneContainer.offsetHeight, 0.1, 1000);

        loadWireframe(function () {
            window.addEventListener('resize', resizeScene, false);
        });

        return () => {
            if (sceneContainer && sceneContainer.firstChild) sceneContainer.removeChild(sceneContainer.firstChild);
        };
    }, []);

    useEffect(() => {
        if (!window.isLoading.phoneWireframeScene) renderScene(progress);
    }, [progress]);

    return (
        <div className="relative full-width">
            <div className="phone-wireframescene-container center-horizontal"/>
        </div>
    );
}

export default Scene;