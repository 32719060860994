import "./storyLine.scss";

import React, {useEffect, useRef, useState} from "react";
import SnakeGame from "../../../components/games/snakeGame/snakeGame";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import PhoneScene from "../../../components/scenes/phoneScene/phoneScene";
import PhoneWireframeScene from "../../../components/scenes/phoneScene/phoneWireframeScene";
import DragonScene from "../../../components/scenes/dragonScene/dragonScene";

const backgroundImages = [];

/**
 * Initializes GSAP animations for storyline elements. Animates line flares and content boxes with ScrollTriggers based on scroll position.
 * @returns {GSAPAnimation[]} Array of animation timelines for flares and content boxes.
 */
function renderStorylineFlyinAnimations() {
    const aniRefs = [];
    const line    = document.querySelector(".story > .line");
    const flare   = document.querySelector(".story > .line > .line-flare");
    const stories = document.querySelectorAll(".story-line > .content-box");

    // Animating story-line flare
    const flareRotation = 360 * 5;
    aniRefs.push(gsap.timeline({
            scrollTrigger: {
                trigger: line,
                start:   `-=${line.offsetHeight * 0.1}`,
                end:     `+=${line.offsetHeight}`,
                scrub:   true,
            }
        }
    ));
    aniRefs[0].fromTo(flare, {
        autoAlpha: 0,
        scale:     .5,
        y:         0,
        rotate:    0,
    }, {
        autoAlpha: 1,
        scale:     1,
        y:         line.offsetHeight * .1,
        rotate:    flareRotation * .1,
        duration: .1
    })
        .to(flare, {
            y:        line.offsetHeight * .8,
            rotate:   flareRotation * .8,
            duration: .8
        })
        .to(flare, {
            y:         line.offsetHeight,
            rotate:    flareRotation,
            scale:     .5,
            autoAlpha: 0,
            duration:  .2
        }, ">-0.2");

    // Animating story-line content-boxes
    aniRefs.push(...Array.from(stories).map(elem => {
        const parent = gsap.timeline({
            scrollTrigger: {
                trigger: elem,
                start:   "top center+=15%",
                end:     "+=500",
                scrub:   true,
                ease:    "ease-out"
            }
        });
        parent.from(elem, {
            scale:     0.95,
            autoAlpha: 0,
            x:         100
        })
            .to(elem, {
                scale:     1,
                autoAlpha: 1,
                x:         0,
                ease:      "ease-out"
            });

        // Animating story-line content-boxes, direct divs with delay
        const children = elem.querySelectorAll(':scope > div');
        children.forEach(child => {
            const delay = parent.duration() * 0.1;

            parent.from(child, {
                scale:     0.95,
                autoAlpha: 0,
                x:         50,
                delay:     delay
            }, "start+=" + delay)
                .to(child, {
                    scale:     1,
                    autoAlpha: 1,
                    x:         0
                }, "fly-in+=" + delay);

        });

        return parent;
    }));

    return aniRefs;
}

/**
 * Sets up GSAP animations for background changes linked to scroll events on specific sections. Adjusts background image and opacity.
 * @param sections {Element[]} Sections triggering the background changes.
 * @returns {GSAPAnimation[]} Array of GSAP animations and ScrollTriggers for background management.
 */
function renderStorylineBackgroundAnimations(sections) {
    const aniRefs = [];
    const backgroundImageRef = document.querySelector(".fixed-background");
    gsap.set(backgroundImageRef, { opacity: 0 });

    sections.forEach((section, index) => {
        aniRefs.push(ScrollTrigger.create({
            trigger: section,
            start: "top center",
            end: "bottom center",
            onEnter: () => changeBackgroundImage(index),
            onEnterBack: () => changeBackgroundImage(index),
            onLeave: () => gsap.set(backgroundImageRef, { opacity: 0 }),
            onLeaveBack: () => gsap.set(backgroundImageRef, { opacity: 0 })
        }));

        aniRefs.push(gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: "top center",
                end: "bottom center",
                scrub: true,
            }
        })
            .fromTo(backgroundImageRef,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 1,
                    ease: "power1.inOut",
                    immediateRender: false
                },
                0)
            .to(backgroundImageRef,
                {
                    opacity: 0,
                    duration: 1,
                    ease: "power1.inOut"
                },
                0.8));
    });

    aniRefs.push(ScrollTrigger.create({
        trigger: sections[0],
        start: "top bottom",
        end: "top bottom",
        onLeaveBack: () => gsap.set(backgroundImageRef, { opacity: 0 }),
        toggleActions: "play none none none"
    }));

    aniRefs.push(ScrollTrigger.create({
        trigger: sections[sections.length - 1],
        start: "bottom top",
        end: "bottom top",
        onLeave: () => gsap.set(backgroundImageRef, { opacity: 0 }),
        toggleActions: "play none none none"
    }));

    function changeBackgroundImage(index) {
        gsap.set(backgroundImageRef, {
            backgroundImage: `url(/assets/img/${backgroundImages[index]})`
        });
    }

    return aniRefs;
}

/**
 * Renders the 3D phone animation with GSAP, transitioning element from Material to Wireframe based on scroll position.
 * @param fromContainer {Element} The container element where the animation starts.
 * @param toContainer {Element} The container element where the animation ends.
 * @param phoneHolder {Element} The element representing the phone in the animation.
 * @param wireframeHolder {Element} The element representing the wireframe in the animation.
 * @param setAniProgress {Function} Callback function to update animation progress.
 * @returns {GSAPAnimation[]} Array of GSAP animations and ScrollTriggers for phone and wireframe animations.
 */
function renderPhoneAnimation(fromContainer, toContainer, phoneHolder, wireframeHolder, setAniProgress){
    const aniRefs = [],
          style   = {
            top: 100
          }

    aniRefs.push(gsap.fromTo(phoneHolder,
        {
            top: `${style.top}px`
        },
        {
            top: `${fromContainer.offsetHeight + style.top}px`,
            duration: 1,
            ease: "power1.inOut",
            scrollTrigger: {
                trigger: toContainer,
                start: "top bottom-=10%",
                end: "bottom bottom",
                scrub: true,
                onUpdate: self => setAniProgress(self.progress)
            }
        }
    ));

    aniRefs.push(gsap.fromTo(wireframeHolder,
        {
            top: `-${fromContainer.offsetHeight - style.top}px`
        },
        {
            top: `${style.top}px`,
            duration: 1,
            ease: "power1.inOut",
            scrollTrigger: {
                trigger: toContainer,
                start: "top bottom-=10%",
                end: "bottom bottom",
                scrub: true
            }
        }
    ));

    return aniRefs;
}

/**
 * Renders the 3D dragon animation with GSAP, rotates the dragon on it's y axis.
 * @param bgContainer {Element} The container element where the animation starts.
 * @param setAniProgress {Function} Callback function to update animation progress.
 * @returns {ScrollTrigger} GSAP ScrollTrigger for dragon animations.
 */
function renderDragonAnimation(bgContainer, setAniProgress) {
    const ease = (value) => { return gsap.parseEase("power1.inOut")(value); }

    return ScrollTrigger.create({
        trigger: bgContainer,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        onUpdate: self => setAniProgress(ease(self.progress))
    });
}

function Component() {
    const [phoneAniProgress, setPhoneAniProgress] = useState(0);
    const [dragonAniProgress, setDragonAniProgress] = useState(0);
    const storyRef                = useRef();
    const designStoryRef          = useRef();
    const backendStoryRef         = useRef();
    const phoneHolderRef          = useRef();
    const phoneWireframeHolderRef = useRef();
    const dragonBgRef             = useRef();
    let aniRefs                   = [];

    useEffect(() => {
        aniRefs = renderStorylineFlyinAnimations();
        /*aniRefs.push(...renderStorylineBackgroundAnimations(storyRef.current.querySelectorAll(".story-line")));*/
        aniRefs.push(...renderPhoneAnimation(designStoryRef.current, backendStoryRef.current, phoneHolderRef.current, phoneWireframeHolderRef.current, setPhoneAniProgress));
        aniRefs.push(renderDragonAnimation(dragonBgRef.current, setDragonAniProgress));

        return () => aniRefs.forEach(elem => {
            if (elem instanceof ScrollTrigger) elem.kill();
            else elem.scrollTrigger.kill();
        });
    }, []);

    return (
        <div ref={storyRef} id="story" className="story">

            <div className="line">
                <div className="line-object"/>
                <div className="line-flare"/>
            </div>

            <div className="story-line start">
                <div className="background-container">
                    <div ref={dragonBgRef} className="background">
                        <DragonScene progress={dragonAniProgress}/>
                    </div>
                </div>

                <div className="content-box">
                    <h3 className="text--xl font-racama">How It Started</h3>
                    <p>
                        At 14 I got kicked out of sports school, then I enrolled in a higher technical institution in
                        Vienna. There, I qualified as a
                        web-developer, gaining six years of experience coding in .NET, NodeJS, and VueJS.
                    </p>
                    <div>
                        <div className="spacer"/>
                        <p>
                            I got my first position as a junior web-developer and got the hang of best-practices and
                            effective leadership.
                        </p>
                    </div>
                    <div>
                        <div className="spacer"/>
                        <p>
                            2022 marks a new beginning, self-employment made me master system build,
                            deployment, and monitoring.
                            It made me improve at sales, meeting tight deadlines, managing stress, and talking to
                            non-technical people.
                        </p>
                    </div>
                    <div>
                        <div className="spacer"/>
                        <p>
                            Over the years I've built products for companies and businesses around the globe ranging
                            from landing pages
                            to complex solutions and enterprise apps with focus on fast, elegant and accessible user
                            experiences.
                        </p>
                    </div>
                </div>
            </div>

            <div ref={designStoryRef} className="story-line design">
            <div className="full-width-container">
                    <div ref={phoneHolderRef} className="phone-scene-holder">
                        <PhoneScene progress={phoneAniProgress}/>
                    </div>
                </div>

                <div className="content-box">
                    <h3 className="text--xl font-racama">Design</h3>
                    <p>
                        I'm probably not the typical designer. I am positioned behind scripts and stylesheets,
                        turning code and pixels into a symphony.
                    </p>
                    <div>
                        <div className="spacer"/>
                        <p>
                            But I design, I like to express my vision, fix conceptual
                            mistakes, make frontends tasteful, craft animations & scenes, and make users jaws
                            drop.
                            Feeding my eyes upon beautiful designs and take inspiration, as well as inspire.
                        </p>
                    </div>
                    <div>
                        <div className="spacer"/>
                        <p>
                            I incorporate psychological principles of Human-Computer Interaction into my UI
                            designs.<br/>
                            A well-designed UI eliminates the need for a tutorial.
                        </p>
                    </div>
                </div>
            </div>

            <div ref={backendStoryRef} className="story-line engineering">
                <div className="full-width-container">
                    <div ref={phoneWireframeHolderRef} className="phone-scene-holder">
                        <PhoneWireframeScene progress={phoneAniProgress}/>
                    </div>
                </div>

                <div className="content-box">
                    <h3 className="text--xl font-racama">Engineering</h3>
                    <p>
                        Years of being forced to do Backend equipped me with knowledge and obsession with data,
                        statistics, efficient
                        logic and performance.<br/>
                    </p>
                    <div>
                        <div className="spacer"/>
                        <p>
                            I have a deep appreciation for developing clean, lean, and scalable codebases.<br/>
                            Junior engineers make it work; senior engineers make it last.
                        </p>
                    </div>
                </div>
            </div>

            <div className="story-line">

                <div className="content-box">
                    <h3 className="text--xl font-racama">Passion</h3>
                    <p>
                        I am passionate about what I do, about big data, the power that computing brings, and predictive analytics.<br/>
                        Relentless technological acceleration is what I thrive for.
                    </p>
                    <div>
                        <div className="spacer"/>
                        <p>
                            I love taking on challenges, as long as they're not very illegal <span className="text--s text-util-500">(or I am under immunity)</span>, and solving them
                            in effective ways.
                        </p>
                    </div>
                    <div>
                        <div className="spacer"/>
                        <p>And when I am bored I implement stupid things, like this snake game.</p>
                        <p className="text--xs font-roboto-mono text-util-500">{"{ lines_of_code: 156 }"}</p>
                        <div className="spacer"/>
                        <SnakeGame/>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Component;