import "./dragonScene.scss";

import React, {useEffect} from "react";
import * as Three from 'three';
import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js';
import {DRACOLoader} from "three/addons/loaders/DRACOLoader.js";
import {isMobile} from "react-device-detect";

/**
 * Initializing scene elements to global loading object.
 */
window.isLoading = {
    ...window.isLoading,
    dragonScene: true,
};
let sceneContainer,
    sceneDom,
    scene    = new Three.Scene(),
    model,
    loader      = new GLTFLoader(),
    dracoLoader = new DRACOLoader(),
    renderer    = new Three.WebGLRenderer({antialias: true, alpha: true, powerPreference: `${isMobile ? "low-power" : "high-performance"}`}),
    pivot       = new Three.Object3D(),
    camera,
    spotLight1,
    spotLight2,
    posY        = 400,
    posZ        = 580,
    posX        = -139,
    rotY        = -.2,
    rotZ        = 0,
    rotX        = -0.23;

/**
 * Initializes and loads the 3D scene including camera, renderer, and model.
 * Sets up the scene environment and starts the rendering process once the GLTF model is loaded.
 * @param _callback {Function} Callback function to execute once the scene is fully loaded.
 */
function loadScene(_callback) {
    // Set scene
    dracoLoader.setDecoderPath('assets/3d/draco/');
    dracoLoader.setDecoderConfig({ type: 'js' });
    loader.setDRACOLoader(dracoLoader);
    renderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
    sceneDom = renderer.domElement;
    sceneContainer.appendChild(sceneDom);

    // Load scene
    loader.load("./assets/3d/dragon/scene.gltf", function (gltf) {
            // Double-loading lock
            if (!window.isLoading.dragonScene) return;

            // Load model
            model = gltf;
            scene.add(model.scene);

            scene.add(pivot);
            pivot.add(model.scene);
            model.scene.position.set(-100, -1, 200);

            // Load texture
            const textureLoader = new Three.TextureLoader().setPath("./assets/img/");
            const texture       = textureLoader.load("software_developer_triangles.jpg");
            texture.minFilter   = Three.LinearFilter;
            texture.magFilter   = Three.LinearFilter;
            texture.colorSpace  = Three.SRGBColorSpace;

            // Spotlight
            spotLight1 = new Three.SpotLight(0xffffff, 60000);
            spotLight1.angle = Math.PI / 4;
            spotLight1.penumbra = 1;
            spotLight1.decay = 2;
            spotLight1.distance = 70000;
            spotLight1.castShadow = true;
            spotLight1.position.set(0, -100, 0);
            spotLight1.map = texture;
            scene.add(spotLight1);

            // Second Spotlight from above
            spotLight2 = new Three.SpotLight(0xffffff, 1600);
            spotLight2.angle = Math.PI / 2;
            spotLight2.penumbra = 1;
            spotLight2.decay = 2;
            spotLight2.distance = 70000;
            spotLight2.castShadow = true;
            spotLight2.position.set(100, 450, 0);
            spotLight2.target.position.set(0, 0, 0);
            scene.add(spotLight2);
            scene.add(spotLight2.target);

            // Camera
            camera.position.y = posY;
            camera.position.z = posZ;
            camera.position.x = posX;
            camera.rotation.y = rotY;
            camera.rotation.z = rotZ;
            camera.rotation.x = rotX;
            scene.add(camera);

            // Render scene
            renderer.render(scene, camera);

            window.isLoading.dragonScene = false;
            _callback();
        }
    );
}

/**
 * Updates and renders the scene based on animation progress.
 * Adjusts camera, light and model dynamically.
 */
function renderScene(aniProgress) {
    pivot.rotation.y = -(aniProgress * Math.PI * .5);
    renderer.render(scene, camera);
}

/**
 * Adjusts the camera's aspect ratio and renderer's size to handle window resizing.
 */
function resizeScene() {
    camera.aspect = sceneContainer.offsetWidth / sceneContainer.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
}

function Scene({ progress }) {
    useEffect(() => {
        sceneContainer = document.querySelector(".dragon-scene-container");
        camera         = new Three.PerspectiveCamera(30, sceneContainer.offsetWidth / sceneContainer.offsetHeight, 0.1, 1000);

        loadScene(function () {
            window.addEventListener('resize', resizeScene, false);
        });

        return () => {
            if (sceneContainer && sceneContainer.firstChild) sceneContainer.removeChild(sceneContainer.firstChild);
        };
    }, []);

    useEffect(() => {
        if (!window.isLoading.dragonScene) renderScene(progress);
    }, [progress]);

    return (
        <div className="center full-width">
            <div className="dragon-scene-container center-horizontal"/>
        </div>
    );
}

export default Scene;