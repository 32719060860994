import "./list.scss";

import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {GitHubLogoIcon} from "@radix-ui/react-icons";

/**
 * Initializes and returns a list of GSAP animations for a set of elements, animating each from a width of 0% to 100%.
 * Each animation is tied to a ScrollTrigger instance that starts and ends based on the element's visibility in the viewport.
 * @param elements {Element[]} An array of DOM elements to be animated.
 * @returns {GSAPAnimation[]} An array of GSAP animation instances.
 */
function renderAnimation(elements) {
    const aniRefs = [];

    elements.forEach(elem => {
        aniRefs.push(gsap.fromTo(elem,
            { width: "0%" },
            {
                width: "100%",
                duration: 1,
                ease: "power1.inOut",
                scrollTrigger: {
                    trigger: elem,
                    start: "bottom bottom",
                    end: "top top+=10%",
                    scrub: true
                }
            }
        ));
    });

    return aniRefs;
}

function Component() {
    const itemRefs   = useRef([]);
    itemRefs.current = [];
    let aniRefs      = [];

    useEffect(() => {
        aniRefs = renderAnimation(itemRefs.current);

        return () => aniRefs.forEach(tl => tl.scrollTrigger.kill());
    }, []);

    const addToRefs = el => { if (el && !itemRefs.current.includes(el)) itemRefs.current.push(el); };

    return (
        <div id="my-works" className="my-works-container">
            <div className="line-top">
                <div className="line-object"/>
            </div>

            <div className="padding-box">
                <h2 className="text--l">My Works</h2>
                <div className="spacer"/>
            </div>

            <div className="my-works">

                <a href="https://www.figma.com/proto/Q52atf6p8y2hc97XnJHxTm/Patricius.dev" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">This Web-App</h3>
                            <span className="text--xs opacity-80">Study & Display</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            Created an interactive and responsive web-app, in order to showcase who I am, my
                            achievements
                            and
                            skills.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">This Web-App</h3>
                                    <span className="text--xs opacity-80">Study & Display</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Designed and built from scratch with React19, SCSS, GSAP, Tailwind, Three.js, Bun.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/DajosPatryk/Study-Operating-System" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">CGA-Screen Operating System</h3>
                            <span className="text--xs opacity-80">Study</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            Created a CGA screen based, simple operating system from scratch. Capable of reading keyboard inputs, printing and doing basic calculations.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">CGA-Screen Operating System</h3>
                                    <span className="text--xs opacity-80">Study</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Written with ASM x86, C and C++. Managing threads, activities by utilizing hardware and event-based interrupts.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                {/*<a href="https://avalonia.space/cases/project/eso-stats" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">ESO-Stats</h3>
                            <span className="text--xs opacity-80">Study & SaaS</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            AI-powered self-sustaining game-wiki for Elder Scrolls Online, made to maintain itself
                            with
                            the
                            power of Regex and GPT-4.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">ESO-Stats</h3>
                                    <span className="text--xs opacity-80">Study & SaaS</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Designed and built from scratch with Stripe, NodeJS, ExpressJS, OpenAI-API, VueJS,
                                    Vuetify,
                                    and
                                    MongoDB.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>*/}

                <a href="https://github.com/DajosPatryk/Template-NodeJS-SecureAPI" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">NodeJS SecureAPI</h3>
                            <span className="text--xs opacity-80">Study</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            Yes, I also develop API's. This study was aimed at creating a secure-API template using Node
                            with >90% test coverage.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">NodeJS SecureAPI</h3>
                                    <span className="text--xs opacity-80">Study</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Built from scratch with NodeJS/Bun, ExpressJS, MongoDB, Swagger, Jest, JWT Auth.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/DajosPatryk/Enterprise_WebApp_Template" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">Nuxt + .NET Template</h3>
                            <span className="text--xs opacity-80">Study</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            This repository is my template for future projects. A monolith template ready to build
                            enterprise-scale web-apps.
                            Tailored for optimal performance, utilizing Controller and Mediator pattern in backend for
                            ease of scalability.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">Nuxt + .NET Template</h3>
                                    <span className="text--xs opacity-80">Study</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Nuxt4, i18n, Tailwind, C# .NET8, Mediator, Entity Framework Core, PostgreSQL, Nginx.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

                <a href="https://portalpreise.com" target="_blank">
                    <div className="item">
                        <div className="content">
                            <h3 className="font-racama whitespace-nowrap">Portalpreise</h3>
                            <span className="text--xs opacity-80">Statistic & SaaS</span>
                        </div>
                        <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                            An open-statistic heatmap of german-speaking regions displayed as polygons, and their
                            average real-estate prices.
                        </div>

                        <div ref={addToRefs} className="animated-background">
                            <div className="item">
                                <div className="content">
                                    <h3 className="font-racama whitespace-nowrap">Portalpreise</h3>
                                    <span className="text--xs opacity-80">Statistic & SaaS</span>
                                </div>
                                <div className="content text--s lg:pl-32 md:pl-8 pl-4">
                                    Designed and built from scratch with NodeJS, ExpressJS, VueJS, Vuetify and
                                    MongoDB.
                                </div>
                            </div>
                        </div>
                    </div>
                </a>

            </div>

            <div className="padding-box">
                <div className="spacer"/>
                <span className="text--s text-util-500">
                    More studies, live-code and tech-breakdown can be found on my <a
                    href="https://github.com/DajosPatryk" target="_blank">GitHub <GitHubLogoIcon className="inline"
                                                                                                 width="1em"
                                                                                                 height="1em"/></a> and the <a
                    href="https://avalonia.space/categories" target="_blank">Avalonia</a> website.
                </span>
            </div>

        </div>
    );
}

export default Component;