import "./supercarScene.scss";

import React, {useEffect} from "react";
import * as Three from 'three';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {isMobile} from "react-device-detect";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {DRACOLoader} from "three/addons/loaders/DRACOLoader.js";

/**
 * Initializing scene elements to global loading object.
 */
window.isLoading = {
    ...window.isLoading,
    supercarScene: window.innerWidth > 580,
};
let sceneContainer,
    sceneDom,
    scene    = new Three.Scene(),
    model,
    loader      = new GLTFLoader(),
    dracoLoader = new DRACOLoader(),
    renderer    = new Three.WebGLRenderer({antialias: true, alpha: true, powerPreference: `${isMobile ? "low-power" : "high-performance"}`}),
    camera,
    spotLight,
    aniRef,
    posY = 2.385,
    posZ = 5.428,
    posX = 9.945,
    rotY = 0.930,
    rotZ = 0.339,
    rotX = -0.414;

/**
 * Initializes and loads the 3D scene including camera, renderer, and model.
 * Sets up the scene environment and starts the rendering process once the GLTF model is loaded.
 * @param _callback {Function} Callback function to execute once the scene is fully loaded.
 */
function loadScene(_callback) {
    // Set scene
    dracoLoader.setDecoderPath('assets/3d/draco/');
    dracoLoader.setDecoderConfig({ type: 'js' });
    loader.setDRACOLoader(dracoLoader);
    renderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
    sceneDom = renderer.domElement;
    sceneContainer.appendChild(sceneDom);

    // Load scene
    loader.load("./assets/3d/audiR8/scene.gltf", gltf => {
            // Double-loading lock
            if (!window.isLoading.supercarScene) return;

            // Load model
            model = gltf;
            scene.add(model.scene);

            // Load texture
            const textureLoader = new Three.TextureLoader().setPath("./assets/img/");
            const texture       = textureLoader.load("software_developer_triangles.jpg");
            texture.minFilter   = Three.LinearFilter;
            texture.magFilter   = Three.LinearFilter;
            texture.colorSpace  = Three.SRGBColorSpace;

            // Spotlight
            spotLight            = new Three.SpotLight(0xffffff, 250);
            spotLight.angle      = Math.PI / 8;
            spotLight.penumbra   = 1;
            spotLight.decay      = 2;
            spotLight.distance   = 70;
            spotLight.castShadow = true;
            spotLight.map        = texture;
            spotLight.position.set(3.5, 6, 2.5);
            scene.add(spotLight);

            // Camera
            camera.position.y = posY;
            camera.position.z = posZ;
            camera.position.x = posX;
            camera.rotation.y = rotY;
            camera.rotation.z = rotZ;
            camera.rotation.x = rotX;
            scene.add(camera);

            // Render scene
            renderer.render(scene, camera);

            window.isLoading.supercarScene = false;
            _callback();
        }
    );
}

/**
 * Updates and renders the scene based on animation progress.
 * Adjusts camera, light and model dynamically.
 */
function renderScene(aniProgress) {
    const aniProgressHalf = Math.min(aniProgress * 2, 1);
    const rotationY       = (Math.PI / 400) * aniProgress;

    spotLight.position.x   = Math.cos(aniProgress) * 0.5 + 7;
    spotLight.position.z   = Math.sin(aniProgress) * 1.7 + 5;
    camera.position.y      = posY + .2 * aniProgressHalf;
    camera.position.x      = posX - 1.645 * aniProgressHalf;
    sceneDom.style.opacity = 1;
    model.scene.rotation.y = rotationY + aniProgress;

    renderer.render(scene, camera);
}

/**
 * Adjusts the camera's aspect ratio and renderer's size to handle window resizing.
 */
function resizeScene() {
    camera.aspect = sceneContainer.offsetWidth / sceneContainer.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(sceneContainer.offsetWidth, sceneContainer.offsetHeight);
}

function Scene({ progress }) {
    useEffect(() => {
        sceneContainer = document.querySelector(".supercar-scene-container");
        camera         = new Three.PerspectiveCamera(30, sceneContainer.offsetWidth / sceneContainer.offsetHeight, 0.1, 1000);

        loadScene(function () {
            window.addEventListener('resize', resizeScene, false);
        });

        return () => {
            if (aniRef instanceof ScrollTrigger) aniRef.kill();
            if (sceneContainer && sceneContainer.firstChild) sceneContainer.removeChild(sceneContainer.firstChild);
        };
    }, []);

    useEffect(() => {
        if (!window.isLoading.supercarScene) renderScene(progress);
    }, [progress]);

    return (
        <div className="relative full-width">
            <div className="supercar-scene-container center-horizontal"/>
        </div>
    );
}

/*
Unused code for displaying values.

<p className="text--xs px-4 text-util-700 font-roboto-mono">{`{ ${text} }`}</p>
setFunc(
    `color: #${toHex(r)}${toHex(g)}${toHex(b)}, ` +
    `posY: ${camera.position.y.toFixed(3)}, posZ: ${camera.position.z.toFixed(3)}, posX: ${camera.position.x.toFixed(3)}, ` +
    `rotY: ${camera.rotation.y.toFixed(3)}, rotZ: ${camera.rotation.z.toFixed(3)}, rotX: ${camera.rotation.x.toFixed(3)}`
);*/

export default Scene;